@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto+Mono:ital,wght@0,100;0,400;0,600;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inria+Sans&family=Noto+Serif+KR:wght@500&family=Roboto+Mono&family=Special+Elite&display=swap');
// Colors
$bg-color: rgb(10, 10, 10);
$dot-color: rgb(105, 103, 99);
//$base-font: 'Roboto Mono', monospace;
$base-font: 'Inria Sans', sans-serif;

// Dimensions
$dot-size: 1px;
$dot-space: 22px;

.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  /* Whatever width you want */
}

body {
  padding: 10px;
  text-align: center;
  font-family: $base-font;
  background: url(./resources/1368534.jpg) no-repeat center center fixed;
  background-size: cover;
  // background: linear-gradient(
  //       90deg,
  //       $bg-color ($dot-space - $dot-size),
  //       transparent 1%
  //     )
  //     center,
  //   linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
  //   $dot-color;
  // background-size: $dot-space $dot-space;
}

.business-card {
  font-family: $base-font;
  display: block;
  // background-color: white;
  // opacity: 50%;
  width: auto;
  color: black;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.33);
  backdrop-filter: blur(20px);
  padding: 10px;
  box-shadow: 0 0 10px rgba(43, 42, 42, 0.66);
}

h1 {
  padding-top: 0;
  margin-top: 0;
}

p {
  font-family: $base-font;
  display: inline;
  background-color: none;
  color: black;
}

input {
  border: none;
  outline: 0 none;
  display: inline-flex;
  font-family: $base-font;
  font-size: 14pt;
  color: grey;
  background: $bg-color;
  border: none;
  border-radius: 8px;
  width: 200px;
  height: auto;
  padding: 4px;
}

label {
  display: inline-flex;
  font-family: $base-font;
  font-size: 14pt;
  background: $bg-color;
  border-radius: 8px;
  width: auto;
}

.input-black {
  padding: 4px;
  margin: 0;
  background: $bg-color;
  border-radius: 10px;
  width: auto;
}

button {
  font-size: 14pt;
  height: 100%;
  width: auto;
  margin-right: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-style: solid;
  font-family: $base-font;
  font-weight: normal;
  display: inline-block;
  background: white;
  border-color: white;
  border-radius: 2px;
  border-width: 1px;
  color: rgb(99, 96, 96);
}

button:hover {
  border-style: solid;
  background: $bg-color;
  border-color: white;
  color: white;
}

button:active {
  border-style: solid;
  background: white;
  border-color: white;
  color: rgb(99, 96, 96);
}

main {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
}

.App {
  padding: 10px;
  text-align: center;
  border-radius: 12px;
  padding: 4px;
  color: white;
}

.highlight {
  background-image: linear-gradient(
    90.69deg,
    #88ffea 13.42%,
    #ff4ecd 42.37%,
    #1a75ff 103.09%
  );
}

.input-wrapper {
  display: inline-flex;
  background-image: linear-gradient(
    90.69deg,
    #88ffea 13.42%,
    #ff4ecd 42.37%,
    #1a75ff 103.09%
  );
  border-radius: 12px;
  padding: 2px;
  margin: 4px;
  align-items: center;
  width: auto;
}
